<template>
  <v-container class="text-center" height="500" justify="center">
    <h1>Märkte</h1>
    <div>
      <admin-calendar 
        @add:market="addMarket" 
      />
    </div>
    <v-divider />
    
    <error-message v-for="(e, index) in errors" :key="index" :error="e" />
    
    <div v-for="markt in marktTabelle" :key="markt.id">
      <admin-calendar 
        :marktData="markt" 
        @add:market="addMarket" 
        @delete:market="deleteMarket"
      />
    </div>

    <v-card v-if="!marktTabelle.length && !errors.length">
      <v-card-title>
        Zur Zeit sind keine Märkte geplant.
      </v-card-title>
    </v-card>

    <!-- DELETED snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
    >
      {{ SnackBarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  <!-- <admin-single-image :imgSlug="'markt'" /> -->
  </v-container>
</template>

<script>
import AdminCalendar from "../../adminComponents/AdminCalendar.vue";
import ErrorMessage from '@/components/errorMessage.vue'
// import AdminSingleImage from '../../adminComponents/AdminSingleImage.vue'
import utils from "@/utils";
import moment from "moment";

export default {
  components: {
    AdminCalendar,
    ErrorMessage,
    // AdminSingleImage,
  },
  data() {
    return {
      marktTabelle: [],
      errors: [],
      snackbar: false,
      SnackBarText: null,
    };
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      }
    }
  },
  methods: {
    async getMarketData() {
        try {
            this.marktTabelle = await utils.loadMarkets();
        } catch (e) {
            this.errors.push(e)
            return false
        }
      this.cleanMarketDates()
    },

    
    addMarket(market) {
      //console.log('adding markt:' + market.market_name)
      
      this.marktTabelle.push(market);

      this.cleanMarketDates()

      this.SnackBarText = 'Neuen Markt gespeichert.'
      this.snackbar = true

    },
    deleteMarket(marketID) {
      console.log('have to remove markt ' + marketID)
      this.marktTabelle = this.marktTabelle.filter(markt => !(markt.key == marketID));

      this.SnackBarText = 'Markt gelöscht.'
      this.snackbar = true
    },
    cleanMarketDates() {
      this.marktTabelle.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
      });
      
      for (let m of this.marktTabelle) {
        if (m.start_date){
          m.start_date = moment(String(m.start_date)).format("YYYY-MM-DD");
        }
        if (m.end_date){
          m.end_date = moment(String(m.end_date)).format("YYYY-MM-DD");
        }
      }
    }
  },
  mounted() {
    this.getMarketData();
  }
};
</script>
