<template>
    <v-card class="adminCard"> 
        <error-message v-for="(e, index) in errors" :key="index" :error="e"/>
        <v-card-title> 
            <span v-if="marktData.market_name" >"{{marktData.market_name}}" bearbeiten:</span>
            <span v-else>Neuen Markt eintragen:</span>
        </v-card-title>
        <v-form
            ref="form"
        >
            <div class="adminCalendarCard">
                <!-- market name -->
                <v-text-field
                    label="Markt Name"
                    outlined
                    v-model="marktData.market_name"
                    class="marktText"
                    required
                    :rules="rules.name"
                ></v-text-field>

                <!-- market description -->
                <v-text-field
                    label="Markt Info Text (optional)"
                    outlined
                    v-model="marktData.infotext"
                    class="marktText"
                ></v-text-field>

                <!-- market link -->
                <v-text-field
                    label="Link zur Marktseite (optional)"
                    outlined
                    v-model="marktData.link"
                    class="marktText"
                ></v-text-field>
            </div>

            <div class="adminCalendarCard">
                <!-- start date picker  -->
                <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="marktData.start_date"
                        label="Start Datum"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="marketDate"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="marktData.start_date"
                        @input="startDateMenu = false"
                    ></v-date-picker>
                </v-menu>

                <!-- end date picker  -->
                <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="marktData.end_date"
                        label="End Datum"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="marketDate"
                        :rules="rules.endDateBeforeStart"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="marktData.end_date"
                        @input="endDateMenu = false"
                    ></v-date-picker>
                </v-menu>
                <v-btn @click="marktData.end_date= null" small>
                    eintägig
                </v-btn>
            </div>
        </v-form>

        <v-card-actions>
            <v-btn @click="saveMarket">
                Änderung speichern
            </v-btn>
            <v-btn v-if="marktData.key" color="red" @click="deleteMarket">
                Markt löschen
            </v-btn>
        </v-card-actions>
            <v-snackbar
                v-model="success"
                :timeout="5000"
            >
                {{ SnackBarText }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="success = false"
                    >
                    Schließen
                    </v-btn>
                </template>
            </v-snackbar>
    </v-card>
</template>

<script>
import utils from '@/utils'
import ErrorMessage from '../components/errorMessage.vue'
export default {
    name: "CalendarForm",
    components: {
        ErrorMessage,
    },
    data () {
        return {
            startDateMenu: false,
            endDateMenu: false,
            SnackBarText: '',
            success: false,
            rules: {
                name: [val => (val || '').length > 0 || 'Dieses Feld bitte ausfüllen'],
                endDateBeforeStart: [val => (!val || ((val) > this.marktData.start_date)) || 'End Datum liegt vor Start Datum']
            },
            errors: [
                // error: {
                //     title: 'errorTitle',
                //     message: 'errorMessage',
                //     type: 'not set yet'
                // },
            ],
            response: null,
        }
    },
    methods: {
        async saveMarket() {
            // First clear error array 
            this.errors = []
            this.success = false

            // check input consistency
            // name given?
            if (!this.marktData.market_name) {
                let newError = {
                    title: 'Bitte Marktnamen angeben',
                    message: 'Feld "Name" darf nicht leer sein, bitte erneut versuchen!',
                    type: 'input'
                }
                this.errors.push(newError)
            }
            // check if startdatum is befor enddatum
            if (this.marktData.end_date && this.marktData.start_date > this.marktData.end_date) {
                let newError = 'Enddatum liegt vor Startdatum; Bitte ein Enddatum eingeben das _nach_ dem Startdatum des Markts liegt, oder "eintägig" auswählen.'
                this.errors.push(newError)
            }
            // check if startdatum is set
            if (!this.marktData.start_date) {
                let newError ='Kein Startdatum angegeben; Bitte ein Startdatum für den Markt eingeben und erneut versuchen.'
                this.errors.push(newError)
            }
                
            // abort in case of errors:
            if (this.errors.length) {
                return false
            }
            
            // now we can try to submit: 
            let response = null
            try {
                if (this.marktData.key) {
                    // updating exisitng market
                    // console.log('updating ' + this.marktData.name)
                    response = await utils.saveMarketEdit(this.marktData)
                    this.SnackBarText = "Markt geändert"
                    this.success = true
                    
                } else {
                    // creating new market
                    console.log('creating new ' +this.marktData.market_name)
                    response = await utils.saveNewMarket(this.marktData)
                    // console.log(response);
                    this.SnackBarText = "Neuen Markt erstellt"
                    this.success = true
                    this.$refs.form.reset()
                    // this.$emit('hello')
                    this.$emit('add:market', response);
                    
                }
                //console.log(response)
            } catch (e) {
                this.errors.push(e)
            }            
        },
        async deleteMarket() {
            console.log('lösche markt...')
            try {
                await utils.deleteMarket(this.marktData.key)
                this.$emit('delete:market', this.marktData.key)
            } catch (e) {
                this.errors.push(e)
            }
        }
        
    },
    props: {
        marktData: {default () { 
            return {
                key: 0,
                market_name: "",
                start_date: "",
                end_date: "",
                infotext: "",
                link: "",
                }
            }
        }
    }
}
</script>

<style scoped>
.adminCalendarCard {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.adminCalendarCard * {
    flex-basis: auto;
    flex:none;
}

.marktText {
    flex: 1 0 auto;
    margin:10px;
}

.marketDate {
    width:150px;
}
</style>