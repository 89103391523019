<template>
  <v-card id="employee-form" elevation="2" class="adminCard">
    <error-message v-for="(e, index) in errors" :error="e" :key="index" />

    <v-card-title>{{ textBlock.slug }}</v-card-title>
    <v-text-field
      label="Titel"
      outlined
      v-model="textBlock.title"
    ></v-text-field>

    <v-textarea
      outlined
      name="input-7-4"
      label="Zitat (optional)"
      v-model="textBlock.quote"
    >
    </v-textarea>
    
    <v-textarea
      outlined
      name="input-7-4"
      label="Text"
      v-model="textBlock.content"
    >
    </v-textarea>

    <v-card-actions>
      <v-btn text @click="handleSubmit">
        Änderung speichern
      </v-btn>
    </v-card-actions>

    <v-snackbar
      v-model="success"
      :timeout="5000"
    >
      Text "{{textBlock.title}}" erfolgreich geändert.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="success = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import utils from "@/utils";
import errorMessage from "../components/errorMessage.vue";

export default {
  components: { errorMessage },
  name: "TextblockForm",
  data() {
    return {
      submitting: false,
      errors: [],
      success: false,
      textBlock: null
    };
  },
  methods: {
    async loadTextBlock() {
      try {
        this.textBlock = await utils.loadTextBlock(this.textSlug);
        console.log("what we got: " + this.textBlock.title);
        this.errors = [];
      } catch (e) {
        this.errors.push(e);
      }
    },

    async handleSubmit() {
      this.submitting = true;
      console.log("handling the submit like a boss");

      // submitting to API
      try {
        let response = await utils.saveTextBlock(this.textBlock);
        console.log(response);
        //cleaning up the parameters

        // great success!
        this.success = true;
        this.errors = [];
      } catch (e) {
        this.errors.push(e);
      }
    },

    clearStatus() {
      this.errors = false;
      this.success = false;
    }
  },
  mounted() {
    this.loadTextBlock();
  },
  props: {
    textSlug: { type: String, required: true }
  }
};
</script>

<style scoped>
[class*="-message"] {
  font-weight: 500;
}

.error-message {
  color: #d33c40;
}

.success-message {
  color: #32a95d;
}
</style>
